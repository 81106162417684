import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import withGoogleAnalytics from '../common/hoc/GA';
import NotAuthenticatedRoute from '../common/hoc/NotAuthenticatedRoute/NotAuthenticatedRoute';
import PrivateRoute from '../common/hoc/PrivateRoute/PrivateRoute';
import { LogoBackground } from '../common/style';

const ExperienceCenter = lazy(() =>
    import('../features/experienceCenter/ExperienceCenter'),
);
const Register = lazy(() => import('../features/register/Register'));
const EmailConfirm = lazy(() =>
    import('../features/emailConfirm/EmailConfirm'),
);
const Login = lazy(() => import('../features/login/Login'));
const ResetPassword = lazy(() =>
    import('../features/resetPassword/ResetPassword'),
);
const Account = lazy(() => import('../features/account/Account'));
const Vehicle = lazy(() => import('../features/vehicle/Vehicle'));
const Privacy = lazy(() => import('../features/agreement/Privacy'));
const Checkout = lazy(() => import('../features/checkout/Checkout'));
const OrderResult = lazy(() => import('../features/orderResult/OrderResult'));
const Quotation = lazy(() => import('../features/quotation/Quotation'));
const Faq = lazy(() => import('../features/faq/Faq'));
const ProductPage = lazy(() => import('../features/productPage/ProductPage'));
const ProductPagePreOrder = lazy(() => import('../features/preOrder/PreOrder'));
const Landing = lazy(() => import('../features/landing/Landing'));
const Blog = lazy(() => import('../features/blog/Blog'));
const Post = lazy(() => import('../features/blog/components/post/Post'));
const Hashtags = lazy(() =>
    import('../features/blog/components/hashtags/Hashtags'),
);
const AboutUs = lazy(() => import('../features/aboutUs/AboutUs'));
const FinancingPage = lazy(() =>
    import('../features/financingPage/FinancingPage'),
);
const Contribution = lazy(() =>
    import('../features/contribution/Contribution'),
);
const PaymentRetry = lazy(() =>
    import('../features/paymentRetry/PaymentRetry'),
);

const TestDrive = lazy(() => import('../features/TestDrive/TestDrivePage'));

const AccessoriesPage = lazy(() =>
    import('../features/accessoriesPage/AccessoriesPage'),
);
const RefurbishedPage = lazy(() =>
    import('../features/refurbishedPage/RefurbishedPage'),
);
const RefurbishedDetails = lazy(() =>
    import('../features/RefurbishedProductPage/RefurbishedProductPage'),
);
const ServiceAtHome = lazy(() =>
    import('../features/serviceAtHome/ServiceAtHome'),
);
const Subscription = lazy(() =>
    import('../features/subscription/Subscription'),
);
const Contact = lazy(() => import('../features/contactUs'));

const B2BPage = lazy(() => import('../features/b2b/B2BPage'));
const RetnalsPage = lazy(() => import('../features/rentalsPage/RentalsPage'));
const FlashSale = lazy(() => import('../features/flashSale/flashSale'));

const Routes = () => {
    return (
        <Suspense fallback={<LogoBackground />}>
            <Switch>
                <NotAuthenticatedRoute
                    exact
                    path="/register"
                    component={Register}
                />
                <NotAuthenticatedRoute
                    exact
                    path="/emailConfirm"
                    component={EmailConfirm}
                />
                <NotAuthenticatedRoute
                    exact
                    path="/login/:status(confirmed)?"
                    component={Login}
                />
                <NotAuthenticatedRoute
                    exact
                    path="/login/:status(reset-password)?"
                    component={Login}
                />
                <NotAuthenticatedRoute
                    exact
                    path="/reset-password"
                    component={ResetPassword}
                />
                <Route exact path="/paid" component={OrderResult} />
                <Route exact path="/quotation" component={Quotation} />
                <PrivateRoute
                    exact
                    path="/payment/:paymentFor(order|vehicle)/:id"
                    component={PaymentRetry}
                />
                <PrivateRoute path="/account" component={Account} />
                <Route
                    path="/checkout/:type(product|service|accessory)"
                    component={Checkout}
                />
                <Route
                    exact
                    path="/:locale(fr|en|de)?/about-us"
                    component={AboutUs}
                />
                <Route
                    exact
                    path="/:locale(fr|en|de)?/leasing-and-financing"
                    component={FinancingPage}
                />
                <Route
                    exact
                    path="/:locale(fr|en|de)?/contact-us"
                    component={Contact}
                />
                <Route
                    exact
                    path="/:locale(en|fr|de)?/test-drive"
                    component={TestDrive}
                />
                <Route
                    exact
                    path="/:locale(en|fr|de)?/special-offers"
                    component={RefurbishedPage}
                />
                <Route
                    exact
                    path="/:locale(en|fr|de)?/special-offers/:id/:productTitle"
                    component={RefurbishedDetails}
                />
                <Route
                    exact
                    path="/:locale(fr|en|de)?/privacy"
                    component={Privacy}
                />
                <Route
                    exact
                    path="/:locale(fr|en|de)?/product/:bike(cargo-beast|mighty-beast|classy-beast|classy-beast-45)"
                    component={ProductPage}
                />
                <Route
                    exact
                    path="/:locale(fr|en|de)?/order/:bike(xplorer-beast|adventure-beast|nespresso-beast)"
                    component={ProductPagePreOrder}
                />
                <Route exact path="/:locale(fr|en|de)?/" component={Landing} />
                <Route exact path="/:locale(fr|en|de)?/blog" component={Blog} />
                <Route
                    exact
                    path="/:locale(fr|en|de)?/blog/:id"
                    component={Post}
                />
                <Route
                    exact
                    path="/:locale(fr|en|de)?/blog/hashtags/:hashtag"
                    component={Hashtags}
                />
                <Route
                    exact
                    path="/:locale(fr|en|de)?/contribution"
                    component={Contribution}
                />
                <Route
                    exact
                    path="/:locale(fr|en|de)?/faq/:category(general|beast|the-app|warranty-insurance|repairs-maintenance|accessories-customization|delivery-return|user-manuals)?"
                    component={Faq}
                />
                <Route
                    exact
                    path="/:locale(fr|en|de)?/service-and-accessories"
                    component={AccessoriesPage}
                />
                <Route
                    exact
                    path="/:locale(fr|en|de)?/my-miloo/service/:id"
                    component={Vehicle}
                />
                <Route
                    exact
                    path="/:locale(fr|en|de)?/experience-center"
                    component={ExperienceCenter}
                />
                <Route
                    exact
                    path="/:locale(fr|en|de)?/services"
                    component={ServiceAtHome}
                />
                <Route
                    exact
                    path="/:locale(fr|en|de)?/subscription"
                    component={Subscription}
                />
                <Route
                    exact
                    path="/:locale(fr|en|de)?/b2b-offer"
                    component={B2BPage}
                />
                <Route
                    exact
                    path="/:locale(fr|en|de)?/rentals"
                    component={RetnalsPage}
                />
                <Route
                    exact
                    path="/:locale(fr|en|de)?/:companyName"
                    component={FlashSale}
                />
                <Redirect to="/:locale(fr|en|de)?/" />
            </Switch>
        </Suspense>
    );
};

export default withGoogleAnalytics(Routes);
//cruisy-beast
