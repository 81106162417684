import { MuiThemeProvider } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import ReactCookieFirst from 'react-cookiefirst';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { setupInterceptors } from './api/interceptors';
import stylesTheme from './common/constants/stylesTheme';
import { liveChatIds } from './config/liveChatConfig';
import muiTheme from './config/muiTheme';
import App from './core/App';
import store from './core/store';
import './i18n';
import GlobalStyle from './styles/GlobalStyle';
import './styles/fonts.scss';

process.env.NODE_ENV === 'production' &&
    Sentry.init({
        dsn: 'https://1b80591d004e435a9b590b06fb511222@o4504209735483392.ingest.sentry.io/4504209741512704',
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });

setupInterceptors(store);

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
};

TagManager.initialize(tagManagerArgs);

let chatId;
if (process.env.REACT_APP_ENV === 'production') {
    chatId = liveChatIds.production[localStorage.getItem('language')];
} else {
    chatId = liveChatIds.dev[localStorage.getItem('language')];
}

ReactDOM.render(
    <React.StrictMode>
        <Helmet>
            {process.env.REACT_APP_ENV !== 'production' && (
                <meta name="robots" content="noindex"></meta>
            )}

            {process.env.REACT_APP_ENV === 'production' && (
                <script
                    async
                    defer
                    src="https://tools.luckyorange.com/core/lo.js?site-id=a0675174"
                ></script>
            )}

            {process.env.REACT_APP_ENV === 'production' && (
                <meta
                    name="google-site-verification"
                    content="6gLh1IIdkHv0E8lMix9BB2trl6ghNtV9uAHy7cKacr4"
                />
            )}

            {/* Odoo Chat below */}
            <link
                rel="stylesheet"
                href="https://futuresprodtest.odoo.com/im_livechat/external_lib.css"
            />
            <script
                type="text/javascript"
                src="https://futuresprodtest.odoo.com/im_livechat/external_lib.js"
            ></script>
            <script
                type="text/javascript"
                src={`https://futuresprodtest.odoo.com/im_livechat/loader/${chatId}`}
            ></script>
        </Helmet>
        <Provider store={store}>
            <ThemeProvider theme={stylesTheme}>
                <GlobalStyle />

                <MuiThemeProvider theme={muiTheme}>
                    {process.env.REACT_APP_ENV === 'production' && (
                        <ReactCookieFirst apiKey="19d88813-8f8c-496b-8e2c-06d55bd25576" />
                    )}
                    <App />
                </MuiThemeProvider>
            </ThemeProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);
